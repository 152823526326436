.navbar {
    background: var(--color-02) !important;
    background-color: var(--color-02) !important;
    position: relative;
    padding: 25px 50px !important;
}

svg.nav_brand {
    height: 70px;
    width: auto;
}

.main-menu .nav_link:not(:first-child) {
    margin-left: 50px;
}

.nav_link {
    font-family: var(--mypoppies-button-font);
    font-size: 12px;
    text-transform: uppercase;
    color: var(--color-01);
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 2px;
}

.nav_link:hover,
.nav_link.active {
    color: var(--color-01);
    position: relative;
}

.main-menu .nav_link.active:before {
    content: "";
    position: absolute;
    width: 130%;
    left: -30%;
    top: 40%;
    height: 2px;
    background: var(--color-12);
}

.navbar-toggler {
    border: none;
    color: var(--color-01);
}

.rigth_menu img {
    width: 20px;
}

span.cart_count {
    font-size: 15px;
    line-height: 1;
}

.rigth_menu .nav_link:not(:first-child) {
    margin-left: 30px;
}

.main_menu_div,
.rigth_menu {
    display: flex !important;
}

.sidebar {
    width: 25vw;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: var(--color-02);
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 1;
    transition: 0.5s;
    overflow-y: scroll;
    height: 100%;
}

.sidebar.active {
    right: 0;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.sidebar-overlay.active {
    opacity: 1;
    visibility: visible;
}

.sd-header {
    height: 120px;
    display: flex;
    padding: 20px 60px;
    align-items: center;
    justify-content: flex-end;
}

.sd-header img {
    width: 20px;
    margin-top: 3rem;
}

.close_sidebar {
    cursor: pointer;
}

.sd-body {
    padding: 0 60px;
}

.sidebar_newsletter_input {
    border: 1px solid var(--color-01) !important;
    border-radius: 0 !important;
    padding: 20px 50px !important;
    margin-bottom: 15px !important;
}

.sidebar_newsletter {
    margin: 20px 0 0 0;
}

@media (max-width: 700px) {
    .navbar {
        padding: 25px 0 !important;
    }

    .navbar-toggler {
        border: none !important;
        padding: 0 !important;
    }

    .offcanvas-header {
        height: 120px;
    }

    .nav_link {
        margin: 10px 0 !important;
        display: block;
    }

    .nav_link.active:before {
        display: none;
    }

    button:focus:not(:focus-visible) {
        box-shadow: none;
    }

    /* .navbar-collapse.collapse.show {
        position: absolute;
        top: 120px;
        background: var(--color-02);
        z-index: 999;
        width: 100%;
        left: 0;
    }

    .navbar-collapse.collapse.show .mt-5 {
        margin-top: 0 !important;
        display: block !important;
        width: 100%;
    } */

    .main_menu_div {
        display: block !important;
    }

    .main_menu_div .rigth_menu {
        display: block !important;
        padding-left: 3rem !important;
    }

    .main_menu_div .rigth_menu .nav_link:first-child {
        display: block !important;
        margin: 10px 0 !important;
    }

    .main_menu_div .rigth_menu .nav_link:not(:first-child) {
        margin: 10px 20px !important;
        display: inline-block;
    }
    .sidebar{
        width: 100vw;
    }
}